import { Suspense } from "react";
import dynamic from "next/dynamic";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";

import LayoutMain from "@/layouts/Main";
import LayoutCoreProvider from "@/layouts/CoreProvider";
import AuthGuard from "@/guards/AuthGuard";

import type { NextPageWithLayout } from "@/pages/_app";
import type { GetStaticProps } from "next";

const ViewComingSoon = dynamic(() => import("@/views/ComingSoon"), {
  suspense: true,
  ssr: false,
});

const ComingSoon: NextPageWithLayout = () => {
  return (
    <Suspense fallback="...">
      <ViewComingSoon />
    </Suspense>
  );
};

ComingSoon.getLayout = (page, _, { translation }) => {
  const { t } = translation;

  return (
    <LayoutCoreProvider
      headParams={{
        title: t("comingSoon")!,
      }}
    >
      {page}
    </LayoutCoreProvider>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale || "")),
    },
  };
};

export default ComingSoon;
